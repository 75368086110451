exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-all-js": () => import("./../../../src/pages/products/all.js" /* webpackChunkName: "component---src-pages-products-all-js" */),
  "component---src-pages-products-bedspread-js": () => import("./../../../src/pages/products/bedspread.js" /* webpackChunkName: "component---src-pages-products-bedspread-js" */),
  "component---src-pages-products-eyemasks-js": () => import("./../../../src/pages/products/eyemasks.js" /* webpackChunkName: "component---src-pages-products-eyemasks-js" */),
  "component---src-pages-products-futon-js": () => import("./../../../src/pages/products/futon.js" /* webpackChunkName: "component---src-pages-products-futon-js" */),
  "component---src-pages-products-mattresses-js": () => import("./../../../src/pages/products/mattresses.js" /* webpackChunkName: "component---src-pages-products-mattresses-js" */),
  "component---src-pages-products-pillows-js": () => import("./../../../src/pages/products/pillows.js" /* webpackChunkName: "component---src-pages-products-pillows-js" */),
  "component---src-pages-products-protectors-js": () => import("./../../../src/pages/products/protectors.js" /* webpackChunkName: "component---src-pages-products-protectors-js" */),
  "component---src-pages-returns-js": () => import("./../../../src/pages/returns.js" /* webpackChunkName: "component---src-pages-returns-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-warrenty-js": () => import("./../../../src/pages/warrenty.js" /* webpackChunkName: "component---src-pages-warrenty-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

