import React from "react"
import { Link } from "gatsby"

const year = new Date().getFullYear()

const Footer = () => {
    return (
        <section className="container-fluid bg-light footer-wrapper">
            <footer>
                <div className="container">
                    <div className="row pb-3 pt-5">
                        <div className="col-12 col-md-8">
                            <div className="row">
                                <div className="col-6 col-sm mb-3">
                                    <h5>Products</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/products/pillows"><span className="nav-link p-0 text-muted">Pillows</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/mattresses"><span className="nav-link p-0 text-muted">Mattresses</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/protectors"><span className="nav-link p-0 text-muted">Mattress Protectors</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/eyemasks"><span className="nav-link p-0 text-muted">Eye Masks</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/bedspread"><span className="nav-link p-0 text-muted">Bedspread</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/products/futon"><span className="nav-link p-0 text-muted">Futon Mattress</span></Link></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm mb-3">
                                    <h5>Support</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/contact"><span className="nav-link p-0 text-muted">Get In Touch</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/faq"><span className="nav-link p-0 text-muted">FAQs</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/returns"><span className="nav-link p-0 text-muted">Returns & Exchanges</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/warrenty"><span className="nav-link p-0 text-muted">Warrenty & Trial</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/sitemap"><span className="nav-link p-0 text-muted">Sitemap</span></Link></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm mb-3">
                                    <h5>About</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/"><span className="nav-link p-0 text-muted">Home</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/features"><span className="nav-link p-0 text-muted">Mattress Features</span></Link></li>
                                        <li className="nav-item mb-2"><Link to="/about"><span className="nav-link p-0 text-muted">Know Us</span></Link></li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm mb-3">
                                    <h5>Community</h5>
                                    <ul className="nav flex-column">
                                        <li className="nav-item mb-2"><Link to="/blog"><span className="nav-link p-0 text-muted"><span className="brand">plumes</span>™ Blog</span></Link></li>
                                    </ul>
                                </div>
                            </div>
                            <hr />
                            <div className="text-center d-none d-md-block">
                                <p>© {year}. All Rights Reserved | AS Solutions.</p>
                            </div>
                        </div>
                        <div className="col col-md-4">
                            <div className="row">
                                <div className="col text-center text-md-start mb-0 mb-md-3 company-info">
                                    <img className="img-fluid footer-logo" src="/logo.png" alt="" />
                                    <hr />
                                    <p>Reach out to us:<br /><span>Monday to Saturday 9:30 am to 5:30 pm</span></p>
                                    <p><i className="bi bi-telephone"></i> +91 9744 488 388</p>
                                    <p><i className="bi bi-envelope"></i> info@plumesmattress.com</p>
                                    <div className="social-media mt-2">
                                        <h5 className="mt-1">Follow us on</h5>
                                        <i className="bi bi-facebook"></i>
                                        <i className="bi bi-instagram"></i>
                                        <i className="bi bi-linkedin"></i>
                                        <i className="bi bi-twitter-x"></i>
                                        <i className="bi bi-youtube"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center d-md-none d-block">
                    <p>© {year}. All Rights Reserved | AS Solutions.</p>
                </div>
            </footer>
        </section>
    )
}

export default Footer